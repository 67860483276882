import React from "react";

const CustomFileInput = ({ onFileSelect }) => {
  const handleFileSelect = (e) => {
    const file = e.target.files[0];
    onFileSelect(file);
  };

  return (
    <label
      style={{
        display: "flex",
        height: "48px",
        padding: "12px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        flex: "1 0 0",
        borderRadius: "8px",
        background: "var(--primery-500, #2898EC)",
        cursor: "pointer",
        color: "white",
      }}
    >
      Загрузить CSV
      <input
        type="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={handleFileSelect}
      />
    </label>
  );
};

export default CustomFileInput;
