import React from "react";
import "./Shop.scss";
import ShopCard from "./ShopCard/ShopCard";
import Tabs from "../Courses/Tabs/Tabs";
import Loader from "../../components/Loader/Loader";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchProductCards,
  fetchCategoriesLists,
  setActiveButton,
  setCategoriesId,
} from "../.././redux/shop";

const Shop = () => {
  const dispatch = useDispatch();
  const shopOpen = false;
  const {
    productCards,
    categoriesLists,
    categoriesId,
    activeButton,
    isLoading,
  } = useSelector((state) => state.shop);

  React.useEffect(() => {
    dispatch(fetchProductCards(categoriesId));
  }, [categoriesId]);

  React.useEffect(() => {
    dispatch(fetchCategoriesLists());
  }, [dispatch]);

  const handleTabClick = (id, buttonId) => {
    dispatch(setActiveButton(buttonId));
    dispatch(setCategoriesId(id));
  };

  if (shopOpen) {
    return (
      <div className="shop">
        <Tabs gap="8px">
          <p
            onClick={() => handleTabClick("", 0)}
            id="0"
            className={activeButton === 0 ? "tab active" : "tab"}
          >
            Все
          </p>
          {isLoading ? (
            <>
              <p className="tab disable">Аксесуары</p>
              <p className="tab disable">Техника</p>
              <p className="tab disable">Мерч</p>
            </>
          ) : (
            <>
              {[]?.map((item) => (
                <p
                  key={item.id}
                  id={item.id + 1}
                  onClick={() => handleTabClick(item.id, item.id + 1)}
                  className={
                    activeButton === item.id + 1 ? "tab active" : "tab"
                  }
                >
                  {item.title.slice(0, 1).toUpperCase() + item.title.slice(1)}
                </p>
              ))}
            </>
          )}
        </Tabs>
        <div className="cards">
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {Array.isArray(productCards)
                ? productCards?.map((item, index) => (
                    <ShopCard key={item.id} index={index} obj={item} />
                  ))
                : null}
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="shop">
      <h2 className="empty">
        🎒 По завершению модуля здесь появятся товары доступные к покупке
      </h2>
    </div>
  );
};

export default Shop;
